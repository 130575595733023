/*
 * Copyright 2023 Adobe. All rights reserved.
 * This file is licensed to you under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License. You may obtain a copy
 * of the License at http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software distributed under
 * the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR REPRESENTATIONS
 * OF ANY KIND, either express or implied. See the License for the specific language
 * governing permissions and limitations under the License.
 */
import { useEffect, useState } from "react";
import {getWorkflowInfo} from "../utils";
import {NO_ACTIVE_WORKFLOW, NO_DATA, LOADING} from "./constants";

export const WorkflowStatus = ({
  endpoint,
  endpointName,
  editable,
  token,
}) => {
  const [status, setStatus] = useState();

  useEffect(() => {
    const path = editable?.resource?.replace(`urn:${endpointName}:`, "") || "";
    getWorkflowInfo({endpoint, token, path}).then(response => setStatus(response)).catch(reason => { return NO_DATA});
  }, [token, endpoint, endpointName]);

  if ( status === undefined) {
    return LOADING;
  }
  if ( status.isRunning === false ) {
    return NO_ACTIVE_WORKFLOW;
  }
  if ( status.title )
    return status.title;
  return NO_DATA;
}

