/*
 * Copyright 2023 Adobe. All rights reserved.
 * This file is licensed to you under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License. You may obtain a copy
 * of the License at http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software distributed under
 * the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR REPRESENTATIONS
 * OF ANY KIND, either express or implied. See the License for the specific language
 * governing permissions and limitations under the License.
 */
import { Badge, ProgressBar } from "@adobe/react-spectrum";
import { useEffect, useState } from "react";
import { startWorkflow } from "../utils";

export const SubmissionProgress = ({
  endpoint,
  endpointName,
  editable,
  token,
  workflowModel,
  workflowTitle,
}) => {
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [status, setStatus] = useState(0);

  useEffect(() => {
    if (!hasSubmitted) handleSubmit(endpointName);
  }, [hasSubmitted, endpointName]);

  const handleSubmit = async (endpointName) => {
    setHasSubmitted(true);
    const payload = editable?.resource?.replace(`urn:${endpointName}:`, "") || "";
    if (payload) {
      setStatus(202);
      setStatus(await startWorkflow({ endpoint, token, workflowModel, workflowTitle, payload }));
    }
  }

  switch (status) {
    case 0:
      return <></>;
    case 202:
      return <ProgressBar isIndeterminate label="Submitting workflow..." />;
    case 200:
      return <Badge variant="positive">Workflow submission started</Badge>;
    default:
      return <Badge variant="negative">Workflow submission failed</Badge>;
  }
}

