/*
 * Copyright 2023 Adobe. All rights reserved.
 * This file is licensed to you under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License. You may obtain a copy
 * of the License at http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software distributed under
 * the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR REPRESENTATIONS
 * OF ANY KIND, either express or implied. See the License for the specific language
 * governing permissions and limitations under the License.
 */
import { Button, Flex, Heading, Text } from "@adobe/react-spectrum";

export const CountZero = ({ endpoint, handleClose }) => {
  return (
    <Flex direction="column" justifyContent="center" alignItems="center" gap="size-200" height="100%">
      <Heading>No workflows found.</Heading>
      <Text>There were no workflows found in your connected AEM Instance: {endpoint}</Text>
      <Button variant="primary" onPress={handleClose}>Close</Button>
    </Flex>
  )
}