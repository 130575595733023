/*
 * Copyright 2023 Adobe. All rights reserved.
 * This file is licensed to you under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License. You may obtain a copy
 * of the License at http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software distributed under
 * the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR REPRESENTATIONS
 * OF ANY KIND, either express or implied. See the License for the specific language
 * governing permissions and limitations under the License.
 */
import React, { useEffect, useState } from "react";
import { Provider, defaultTheme } from "@adobe/react-spectrum";
import ErrorBoundary from "react-error-boundary";
import { HashRouter as Router, Switch, Route } from "react-router-dom";
import { Workflows } from "./Workflows/Index";
import Register from "./Register";

const updateHTMLWithSpectrumClasses = () => {
  const appRoot = document.querySelector("#root > div");
  if (appRoot) {
    const appRootClasses = Array.from(appRoot.classList);
    document.documentElement.classList.add(...appRootClasses);
  }
};

function App(props) {
  const [colorScheme, setColorScheme] = useState("light");
  // use exc runtime event handlers
  // respond to configuration change events (e.g. user switches org)
  props.runtime.on("configuration", ({ imsOrg, imsToken, locale }) => {
    console.log("configuration change", { imsOrg, imsToken, locale });
  });
  // respond to history change events
  props.runtime.on("history", ({ type, path }) => {
    console.log("history change", { type, path });
  });

  useEffect(() => {
    updateHTMLWithSpectrumClasses();
  }, []);

  return (
    <ErrorBoundary onError={onError} FallbackComponent={fallbackComponent}>
      <Router>
        <Provider theme={defaultTheme} colorScheme={colorScheme}>
          <Switch>
            <Route exact path="/">
              <Register />
            </Route>
            <Route exact path="/workflows">
              <Workflows {...props} setColorScheme={setColorScheme} />
            </Route>
          </Switch>
        </Provider>
      </Router>
    </ErrorBoundary>
  );

  // error handler on UI rendering failure
  function onError(e, componentStack) {}

  // component to show if UI fails rendering
  function fallbackComponent({ componentStack, error }) {
    return (
      <React.Fragment>
        <h1 style={{ textAlign: "center", marginTop: "20px" }}>
          Something went wrong :(
        </h1>
        <pre>{componentStack + "\n" + error.message}</pre>
      </React.Fragment>
    );
  }
}

export default App;
