/*
 * Copyright 2023 Adobe. All rights reserved.
 * This file is licensed to you under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License. You may obtain a copy
 * of the License at http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software distributed under
 * the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR REPRESENTATIONS
 * OF ANY KIND, either express or implied. See the License for the specific language
 * governing permissions and limitations under the License.
 */
import { Cell, Column, Heading, Row, TableView, TableBody, TableHeader, Text, View } from "@adobe/react-spectrum";
import { useEffect, useState } from "react";
import {filterEditables, getEditablesByResources, getEditableWithResource} from "../utils";
import { SubmissionProgress } from "./SubmissionProgress";
import {WorkflowStatus} from "./WorkflowStatus";

const defaultColumns = [
  { name: "Name", key: "name", width: "1fr" },
  { name: "Path", key: "path", width: "5fr" }, 
  { name: "Status", key: "status", width: "2fr" },
];

const setupRows = (editables, isSubmittingWorkflows, selectedKeys, endpointName) => {
  const rowItems = !isSubmittingWorkflows ? editables : editables.filter((editable) => selectedKeys.has(editable.id));

  return rowItems.map((editable) => {
    return {
      id: editable.id,
      name: editable.resource.startsWith(`urn:${endpointName}:/content/dam`) ? "Content Fragment" : "Page",
      path: editable.resource.replace(`urn:${endpointName}:`, ""),
      status: isSubmittingWorkflows,
    };
  });
};
export const Editables = ({
  endpoint,
  endpointName,
  editorState,
  isSubmittingWorkflows,
  setHasSelectedKeys,
  token,
  workflowModel,
  workflowTitle,
}) => {
  const [editables, setEditables] = useState(filterEditables(editorState, endpointName));
  const [selectedKeys, setSelectedKeys] = useState(new Set(Object.keys(editorState?.selected || [])));
  const [rows, setRows] = useState(setupRows(editables, isSubmittingWorkflows, selectedKeys));

  useEffect(() => {
    setRows(setupRows(editables, isSubmittingWorkflows, selectedKeys, endpointName));
  }, [editables, isSubmittingWorkflows, selectedKeys, endpointName, token]);

  useEffect(() => {
    setEditables(filterEditables(editorState, endpointName));
  }, [editorState, endpointName]);

  const handleSelection = (keys) => {
    setSelectedKeys(keys);
    setHasSelectedKeys(keys.size > 0);
  };

  useEffect(() => {
    const selectedKeys = new Set(Object.keys(editorState?.selected || []));
    const selectedEditables = editorState?.editables.filter((editable) => selectedKeys.has(editable.id));
    const selectedEditablesWithResources = selectedEditables.map((editable) => getEditableWithResource(editorState?.editables, editable));
    const resources = selectedEditablesWithResources.map((editable) => editable.resource);
    const editablesByResources = getEditablesByResources(editables, resources);
    setSelectedKeys(new Set(editablesByResources.map((editable) => editable.id)));
  }, [editorState, editables])


  if (editables.length === 0) {
    return ( 
      <View>
        <Heading>No editable elements found</Heading>
        <Text>There are no editable elements available for workflows.</Text>
      </View>
    )
  } else {
    return (
      <View>
        <Heading>Content to include in workflow</Heading>
        <TableView
          aria-label="Editable Elements available for workflows"
          selectionMode={isSubmittingWorkflows ? "none" : "multiple"}
          selectedKeys={selectedKeys}
          onSelectionChange={handleSelection}
        >
          <TableHeader columns={defaultColumns}>
            {item => (<Column key={item.key} width={item.width}>{item.name}</Column>)}
          </TableHeader>
          <TableBody items={rows}>
            {item => (
              <Row key={item.id}>
                <Cell>{item.name}</Cell>
                <Cell>{item.path}</Cell>
                <Cell>
                {item.status && (
                  <SubmissionProgress
                    editable={editables.filter(editable => editable.id === item.id)[0]}
                    endpoint={endpoint}
                    endpointName={endpointName}
                    token={token}
                    workflowModel={workflowModel}
                    workflowTitle={workflowTitle}
                  />
                )}
                {!item.status && (
                    <WorkflowStatus
                      editable={editables.filter(editable => editable.id === item.id)[0]}
                      endpoint={endpoint}
                      endpointName={endpointName}
                      token={token}
                    />
                )}
                </Cell>
              </Row>
            )}
          </TableBody>
        </TableView> 
      </View>
    );
  }
};
