/*
 * Copyright 2023 Adobe. All rights reserved.
 * This file is licensed to you under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License. You may obtain a copy
 * of the License at http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software distributed under
 * the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR REPRESENTATIONS
 * OF ANY KIND, either express or implied. See the License for the specific language
 * governing permissions and limitations under the License.
 */
import { Button, Flex, Item, Picker, ProgressBar, TextField } from "@adobe/react-spectrum";
import { useEffect, useState } from "react";
import { getWorkflows } from "../utils";
import { CountZero } from "./CountZero";
import { FetchError } from "./FetchError";

const fetchWorkflows = async ({ endpoint, setIsErrorWithWorkflows, setIsLoadingWorkflows, setIsNoWorkflows, setWorkflows, token }) => {
  const workflowsResponse = await getWorkflows({ endpoint, token });
  if (workflowsResponse !== undefined) {
    if (workflowsResponse.length === 0) {
      setIsNoWorkflows(true);
    } else {
      setWorkflows(workflowsResponse);
      setIsNoWorkflows(false);
    }
    setIsErrorWithWorkflows(false);
  } else {
    setIsErrorWithWorkflows(true);
    console.log("empty workflowsResponse", workflowsResponse);
  }
  setIsLoadingWorkflows(false);
};

export const Selection = ({
  endpoint,
  handleClose,
  hasSelectedKeys,
  isSubmittingWorkflows,
  setIsSubmittingWorkflows,
  token,
  setWorkflowModel,
  setWorkflowTitle,
  workflowModel,
  workflowTitle,
}) => {
  const [isDisabled, setIsDisabled] = useState(false);
  const [isErrorWithWorkflows, setIsErrorWithWorkflows] = useState(false);
  const [isLoadingWorkflows, setIsLoadingWorkflows] = useState(true);
  const [isNoWorkflows, setIsNoWorkflows] = useState(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const [workflows, setWorkflows] = useState([]);

  useEffect(() => {
    // prevent multiple fetches of workflows
    if (token !== "" && endpoint !== "" && workflows.length === 0 && !isErrorWithWorkflows && !isNoWorkflows) {
      fetchWorkflows({ endpoint, setIsErrorWithWorkflows, setIsLoadingWorkflows, setIsNoWorkflows, setWorkflows, token });
    } else if (token === "" && endpoint === "") {
      console.log("Couldn't get workflows, token or endpoint is missing", { token, endpoint });
    }
  }, [token, endpoint, workflows]);

  // handle enabled/disabled state of picker, textfield, and submit button
  useEffect(() => {
    if (isSubmittingWorkflows) {
      setIsSubmitDisabled(true);
      setIsDisabled(true);
    } else {
      if (workflowModel !== "" && workflowTitle !== "" && hasSelectedKeys) {
        setIsSubmitDisabled(false);
      } else {
        setIsSubmitDisabled(true);
      }
      setIsDisabled(false);
    }
  }, [isSubmittingWorkflows, workflowModel, workflowTitle, hasSelectedKeys]);

  const handleSubmit = async () => {
    setIsSubmittingWorkflows(true);
    setIsDisabled(true);
    setIsSubmitDisabled(true);
  };

  if (isNoWorkflows) {
    return <CountZero handleClose={handleClose} endpoint={endpoint} />;
  } else if (isErrorWithWorkflows) {
    return <FetchError handleClose={handleClose} endpoint={endpoint} />;
  } else {
    return (
      <Flex direction="row" width="100%" gap="size-200" justifyContent="space-between">
        <Flex flex="1" gap="size-200" justifyContent="space-evenly">
          {isLoadingWorkflows ? (
            <Flex flex="1">
              <ProgressBar label="Loading workflows..." isIndeterminate />
            </Flex>
          ) : (
            <Picker
              label="Workflow Model"
              items={workflows}
              onSelectionChange={setWorkflowModel}
              flex="1"
              isDisabled={isDisabled}
            >
              {(item) => <Item key={item.value}>{item.label}</Item>}
            </Picker>
          )}
          <TextField label="Name" value={workflowTitle} onChange={setWorkflowTitle} flex="1" isDisabled={isDisabled} />
        </Flex>
        <Flex alignSelf="end" gap="size-200">
          <Button
            variant="primary"
            alignSelf="end"
            margin-top="size-100"
            onPress={handleClose}
          >Close</Button>
          <Button
            variant="accent"
            alignSelf="end"
            margin-top="size-100"
            onPress={handleSubmit}
            isDisabled={isSubmitDisabled}
          >Start Workflow</Button>
        </Flex>
      </Flex>
    )
  }
}
