/*
 * Copyright 2023 Adobe. All rights reserved.
 * This file is licensed to you under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License. You may obtain a copy
 * of the License at http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software distributed under
 * the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR REPRESENTATIONS
 * OF ANY KIND, either express or implied. See the License for the specific language
 * governing permissions and limitations under the License.
 */
import React, { useEffect, useState } from "react";
import { View } from "@adobe/react-spectrum";
import { attach } from "@adobe/uix-guest";
import { Loading } from "./Loading";
import { Selection } from "./Selection";
import { Editables } from "./Editables";
import { EXTENSION_ID } from "../../constants";

export const Workflows = (props) => {
  const [isSubmittingWorkflows, setIsSubmittingWorkflows] = useState(false);
  const [guestConnection, setGuestConnection] = useState();
  const [token, setToken] = useState("");
  const [editorState, setEditorState] = useState({});
  const [endpoint, setEndpoint] = useState("");
  const [endpointName, setEndpointName] = useState("");
  const [hasSelectedKeys, setHasSelectedKeys] = useState(new Set([]));
  const [workflowModel, setWorkflowModel] = useState("");
  const [workflowTitle, setWorkflowTitle] = useState("");

  const init = async () => {
    const connection = await attach({
      id: EXTENSION_ID,
    });
    setGuestConnection(connection);
  };

  useEffect(() => {
    init().catch((e) =>
      console.log("Extension got the error during initialization:", e)
    );
  }, []);

  // Get basic state from guestConnection
  useEffect(() => {
    if (!guestConnection) {
      return;
    }
    const getState = async () => {
      const tempEditorState = await guestConnection.host.editorState.get();
      if (!tempEditorState) return;
      setEditorState(tempEditorState);

      const context = guestConnection.sharedContext;
      const theme = context.get("theme");
      const imsToken = context.get("token");
      const { connections, customTokens } = tempEditorState;
      const tempEndpointName = Object.keys(connections).filter((key) =>
        connections[key].startsWith("aem:")
      )[0];

      if (theme) props.setColorScheme(theme);
      if (tempEndpointName) {
        setEndpointName(tempEndpointName);
        setEndpoint(connections[tempEndpointName].replace("aem:", ""));
        if (customTokens && customTokens[tempEndpointName]) {
          setToken(customTokens[tempEndpointName]);
        } else if (imsToken) {
          setToken("Bearer " + imsToken);
        }
      }
    };

    getState().catch((e) => console.log("Extension error:", e));
  }, [guestConnection]);

  const handleClose = () => {
    if (guestConnection) {
      guestConnection.host.modal.close();
    }
  };

  if (!guestConnection || (!endpoint && !token)) {
    return (
      <View width="99%" height="100%">
        <Loading handleClose={handleClose} />
      </View>
    );
  } else {
    return (
      <View width="99%" height="100%">
        <Selection
          endpoint={endpoint}
          handleClose={handleClose}
          hasSelectedKeys={hasSelectedKeys}
          setIsSubmittingWorkflows={setIsSubmittingWorkflows}
          setWorkflowModel={setWorkflowModel}
          setWorkflowTitle={setWorkflowTitle}
          token={token}
          workflowModel={workflowModel}
          workflowTitle={workflowTitle}
        />
        <Editables
          isSubmittingWorkflows={isSubmittingWorkflows}
          editorState={editorState}
          setHasSelectedKeys={setHasSelectedKeys}
          endpoint={endpoint}
          endpointName={endpointName}
          token={token}
          workflowModel={workflowModel}
          workflowTitle={workflowTitle}
        />
      </View>
    );
  }
};
