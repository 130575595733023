/*
 * Copyright 2023 Adobe. All rights reserved.
 * This file is licensed to you under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License. You may obtain a copy
 * of the License at http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software distributed under
 * the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR REPRESENTATIONS
 * OF ANY KIND, either express or implied. See the License for the specific language
 * governing permissions and limitations under the License.
 */
export const EXTENSION_WIDTH = 1100;
export const EXTENSION_HEIGHT = 600;
export const EXTENSION_ID = "com.adobe.universal-editor-workflow-extension";
export const GET_WORKFLOWS_ENDPOINT = "/libs/cq/gui/content/common/listworkflows.html";
export const START_WORKFLOWS_ENDPOINT = "/var/workflow/instances";

export const GET_PAGEINFO_ENDPOINT = "/libs/wcm/core/content/pageinfo.json?path=";
