/*
 * Copyright 2023 Adobe. All rights reserved.
 * This file is licensed to you under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License. You may obtain a copy
 * of the License at http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software distributed under
 * the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR REPRESENTATIONS
 * OF ANY KIND, either express or implied. See the License for the specific language
 * governing permissions and limitations under the License.
 */

import {
    GET_WORKFLOWS_ENDPOINT,
    START_WORKFLOWS_ENDPOINT,
    GET_PAGEINFO_ENDPOINT
} from "../constants";
import fetch from "node-fetch";

const convertHTMLToJSON = (html) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, "text/html");
  const select = doc.querySelector("coral-select");
  const options = Array.from(select.querySelectorAll("coral-select-item"));
  return options.map((option) => ({
    value: option.getAttribute("value"),
    label: option.textContent,
  }));
};

export const getWorkflows = async ({ endpoint, token }) => {
	if (endpoint === "" || token === "") return "";
  try {
      const apiEndpoint = `${endpoint}${GET_WORKFLOWS_ENDPOINT}`;
      const fetchSettings = {
          method: "GET",
          headers: {
              "Content-Type": "text/html; charset=UTF-8",
              "Authorization": token,
          },
      }

      const response = await fetch(apiEndpoint, fetchSettings);

      if (response.status === 200) {
      const data = await response.text();
      return data.startsWith("{") && data !== null ? "" : convertHTMLToJSON(data);
    } else {
      return undefined;
    }
  } catch(e) {
    console.log("error", e);
  }

  return "";
};

export const startWorkflow = async ({ endpoint, token, workflowModel, workflowTitle, payload }) => {
	if (endpoint === "" || token === "") return undefined;
  try {
      const apiEndpoint = `${endpoint}${START_WORKFLOWS_ENDPOINT}`;
      const formData = new URLSearchParams();
      formData.append("_charset_", "utf-8");
      formData.append(":status", "browser");
      formData.append("payloadType", "JCR_PATH");
      formData.append("payload", payload);
      formData.append("model", workflowModel);
      formData.append("workflowTitle", workflowTitle);

      const fetchSettings = {
          method: "POST",
          headers: {
              "Content-Type": "application/x-www-form-urlencoded",
              "Authorization": token,
          },
          body: formData,
      }

      const response = await fetch(apiEndpoint, fetchSettings);
      if ( response.ok ) return 200;
    return response.status;
  } catch(e) {
    console.log("error", e);
  }

  return undefined;
};

export const getWorkflowInfo = async ({ endpoint, token, path }) => {
    if (endpoint === "" || token === "") return undefined;
    try {
        const fetchSettings = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": token,
            },
        }
        const pageInfo = await fetchContent(fetchSettings, `${endpoint}${GET_PAGEINFO_ENDPOINT}${path}`)
        const workflow = pageInfo.workflow;

        let title = "";
        if ( workflow.isRunning) {
            const instanceId = workflow.id;
            const instance = await fetchContent(fetchSettings,  `${endpoint}${instanceId}.json`);

            const modelId = instance.model;
            const model = await fetchContent(fetchSettings,  `${endpoint}${modelId}.json`);
            title = model.title;
        }
        return { isRunning: workflow.isRunning, title};
    } catch(e) {
        console.log("error", e);
    }
    return undefined;
};

export const filterEditables = (editorState, endpointName) => {
  const filteredArray = (editorState?.editables || [])
    .filter(editable => editable.resource?.startsWith(`urn:${endpointName}`))
    .map(editable => ({
      ...editable,
        resource: editable.resource.replace(/\/jcr:content\/.*/, ""),
    }))
    .filter((editable, index, self) =>
      index === self.findIndex(el => el.resource === editable.resource)
    );

  return filteredArray;
};

const fetchContent = async (fetchSettings, apiEndpoint) => {
    const response = await fetch(apiEndpoint, fetchSettings);
    if (!response.ok) {
        throw new Error(`request to ${apiEndpoint} failed with status code ${response.status}`);
    }
    return await response.json()
}

export const getEditableWithResource = (editables, editable) => {
    if ( editable === undefined ) {
        return undefined;
    }
    if ( editable.resource !== "" ) {
        return editable;
    }
    if ( editable.parentid !== "" ) {
        const parentEditable = editables.find(function(el){
            return el.id === editable.parentid
        });
        return getEditableWithResource(editables, parentEditable);
    }
    return undefined;
}

export const getEditablesByResources = (editables, resources) => {
    resources = resources.map(resource => { return resource.replace(/\/jcr:content\/.*/, "") });
    return resources.map(resource => { return editables.find(function(el){ return el.resource === resource }) });
}