import React, { useEffect, useState } from 'react'
import { EXTENSION_ID } from "../constants";
import { register } from "@adobe/uix-guest";

const Register = () => {
  const init = async () => {
    const guestConnection = await register({
      id: EXTENSION_ID,
      methods: {
        headerMenu: {
          getButtons() {
            return [
              {
                id: "universal-editor-workflow-button",
                icon: "Workflow",
                variant: "action",
                label: "Workflows",
                onClick: async () => {
                  await guestConnection.host.modal.showUrl({
                    title: "Start a Workflow",
                    url: "/index.html#/workflows",
                    fullscreen: true,
                  });
                },
              },
            ];
          },
        },
      }
    });
  };

  useEffect(() => {
    init().catch(console.error);
  }, []);

  return <>Workflows extension loaded!</>;
};

export default Register;